import React, { useState } from "react";
import { customerReviews } from "../Scripts/reviews";
import "../Styles/Reviews.css";

function Reviews() {
  let rMessage, rName, rLocation;
  const reviewsLength = customerReviews.length - 1;
  const [review, setReview] = useState(0);

  // back to previous review
  const backBtnClick = () => {
    setReview(review <= 0 ? reviewsLength : review - 1);
    handleReviewsUpdation();
  };

  // go to newer review
  const frontBtnClick = () => {
    setReview(review >= reviewsLength ? 0 : review + 1);
    handleReviewsUpdation();
  };

  // update reviews
  const handleReviewsUpdation = () => {
    const reviewMessage = customerReviews[review];
    rName = reviewMessage.name;
    rLocation = reviewMessage.location;
    rMessage = reviewMessage.message;
  };

  // list review on visit
  handleReviewsUpdation();

  return (
    <div className="review-section" id="reviews">&nbsp;
      <div className="review-section">
        <div className="rw-text-content">
          <p className="rw-text-title">
            Más información <span className="rw-text-num">+100 proyectos exitosos</span>
          </p>

          <p className="rw-text-desc">La experiencia de nuestros clientes habla por sí misma</p>

          {/* <p className="rw-text-format">
            <span className="rw-text-quote1">''</span>
            <span className="rw-review">{rMessage}</span>
            <span className="rw-text-quote2">''</span>
          </p>

          <div className="rw-authors">
            <div className="rw-names">
              <p className="rw-reviewer-name">{rName}</p>
              <p className="rw-reviewer-place">{rLocation}</p>
            </div>

            <div className="rw-btns">
              <button
                className="rw-next-btn"
                type="button"
                onClick={backBtnClick}
              >
                ←
              </button>
              <button
                className="rw-next-btn"
                type="button"
                onClick={frontBtnClick}
              >
                →
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Reviews;
