import React from "react";
import ITGroup from "../Assets/doctor-group.png"; // Actualizar con una imagen relevante
import SolutionStep from "./SolutionStep";
import "../Styles/About.css";

function About() {
  return (
    <div className="about-section" id="about">&nbsp;
      <div className="about-section">
        <div className="about-image-content">
          <img src={ITGroup} alt="IT Group" className="about-image1" />
        </div>

        <div className="about-text-content">
          <h3 className="about-title">
            <span>Sobre nosotros</span>
          </h3>
          <p className="about-description">
            Bienvenido a EServices Entertained, su socio de confianza para soluciones tecnológicas innovadoras. 
            Nuestro equipo de expertos en TI ofrece servicios personalizados en desarrollo de software, 
            gestión de infraestructura, ciberseguridad y más. Estamos aquí para ayudar a su empresa a 
            crecer y prosperar en el mundo digital.
          </p>

          <h4 className="about-text-title">Nuestras soluciones</h4>

          <SolutionStep
            title="Elija un servicio"
            description="Explore nuestras soluciones en tecnología, desde desarrollo de software a implementación de infraestructura, diseñadas para optimizar su negocio."
          />

          <SolutionStep
            title="Establezca una fecha"
            description="Coordine con nuestro equipo de expertos para una consulta o proyecto a medida, en el momento que más le convenga."
          />

          <SolutionStep
            title="Obtenga resultados"
            description="Reciba soluciones prácticas y personalizadas que impulsarán la eficiencia y productividad de su organización."
          />
        </div>
      </div>
    </div>
  );
}

export default About;
