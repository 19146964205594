import React from "react";
import InformationCard from "./InformationCard";
import { faCode, faProjectDiagram, faChartBar } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Info.css";

function Info() {
  return (
    <div className="info-section" id="services">&nbsp;
      <div className="info-section">
        <div className="info-title-content">
          <h3 className="info-title">
            <span>¿Qué hacemos?</span>
          </h3>
          <p className="info-description">
            Brindamos soluciones tecnológicas personalizadas para impulsar su negocio. 
            Nuestro equipo experto se especializa en desarrollo de software a medida, 
            gestión integral de proyectos, asesoría tecnológica y servicios de 
            inteligencia de negocios (BI) y análisis de datos (BA). Estamos comprometidos 
            a ofrecer resultados que marquen la diferencia.
          </p>
        </div>

        <div className="info-cards-content">
          <InformationCard
            title="Desarrollo de software"
            description="Creamos aplicaciones a medida adaptadas a las necesidades específicas de su negocio. 
            Desde sistemas de gestión empresarial hasta plataformas innovadoras, 
            entregamos soluciones escalables y de alto rendimiento."
            icon={faCode}
          />

          <InformationCard
            title="Gestión de proyectos"
            description="Llevamos sus ideas desde la planificación hasta la ejecución exitosa. 
            Nuestro enfoque estructurado garantiza que sus proyectos tecnológicos 
            se completen a tiempo y dentro del presupuesto."
            icon={faProjectDiagram}
          />

          <InformationCard
            title="Inteligencia de negocios y análisis"
            description="Aproveche el poder de sus datos con nuestros servicios de BI y BA. 
            Le ayudamos a transformar datos complejos en información accionable, 
            optimizando la toma de decisiones estratégicas."
            icon={faChartBar}
          />
        </div>
      </div>
    </div>
  );
}

export default Info;
