import React from "react";
import LegalDocs from "../Components/LegalDocs";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

function Legal() {
  return (
    <div className="home-section">
      <Navbar />
      <LegalDocs />
      <Footer />
    </div>
  );
}

export default Legal;
