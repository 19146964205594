import React from "react";
import Teamwork from "../Assets/doctor-book-appointment.png"; // Imagen relevante para tecnología
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "../Styles/BookAppointment.css";

function BookAppointment() {
  const navigate = useNavigate();

  const handleBookAppointmentClick = () => {
    navigate("/appointment");
  };

  return (
    <div className="ba-section">
      <div className="ba-image-content">
        <img src={Teamwork} alt="Team Collaboration" className="ba-image1" />
      </div>

      <div className="ba-text-content">
        <h3 className="ba-title">
          <span>Por qué elegirnos</span>
        </h3>
        <p className="ba-description">
          Descubra cómo nuestras soluciones tecnológicas pueden transformar su negocio. 
          Nos especializamos en desarrollo de software a medida, gestión de proyectos, 
          asesoría tecnológica y análisis avanzado de datos, ayudándole a alcanzar sus objetivos estratégicos.
        </p>

        <p className="ba-checks ba-check-first">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} /> Soluciones personalizadas para su negocio
        </p>
        <p className="ba-checks">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} /> Equipo de expertos con amplia experiencia
        </p>
        <p className="ba-checks">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} /> Análisis de datos para decisiones estratégicas
        </p>
        <p className="ba-checks ba-check-last">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} /> Procesos claros y eficientes
        </p>

        <button
          className="text-appointment-btn"
          type="button"
          onClick={handleBookAppointmentClick}
        >
          <FontAwesomeIcon icon={faCalendarCheck} /> Solicitar una consulta
        </button>
      </div>
    </div>
  );
}

export default BookAppointment;
