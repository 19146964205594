import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import {
  faCommentDots,
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import "../Styles/Navbar.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function Navbar() {
  const [nav, setNav] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const openNav = () => {
    setNav(!nav);
  };
  const handleBookAppointmentClick = () => {
    navigate("/appointment");
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleChatBtnClick = () => {
    if (!isButtonDisabled) {
      const phoneNumber = "56964644588"; // Reemplaza con el número de teléfono al que quieres redirigir
      const message = encodeURIComponent("Hola, necesito ayuda con...");
      const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;

      window.open(whatsappUrl, "_blank");
    }
    // if (!isButtonDisabled) {
    //   toast.info("Experiencing high traffic, Please wait a moment.", {
    //     position: toast.POSITION.TOP_CENTER,
    //     onOpen: () => setIsButtonDisabled(true),
    //     onClose: () => setIsButtonDisabled(false),
    //   });
    // }
  };

  return (
    <div className="navbar-section">
      <h1 className="navbar-title" onClick={scrollToTop}>
        <Link to="/">
          EServices <span className="navbar-sign">Entertained</span>
        </Link>
      </h1>

      {/* Desktop */}
      <ul className="navbar-items">
        <li onClick={scrollToTop}>
          <Link to="/" className="navbar-links">
            Inicio
          </Link>
        </li>
        <li>
          <a href="#services" className="navbar-links">
            Servicios
          </a>
        </li>
        <li>
          <a href="#about" className="navbar-links">
            Sobre nosotros
          </a>
        </li>
        {/* <li>
          <a href="#reviews" className="navbar-links">
            Experiencias
          </a>
        </li>
        <li>
          <a href="#doctors" className="navbar-links">
            Profesionales
          </a>
        </li> */}
        <li>
          <a className="navbar-links" onClick={handleBookAppointmentClick}>
            Contacto
          </a>
        </li>
      </ul>

      <button
        className="navbar-btn"
        type="button"
        disabled={isButtonDisabled}
        onClick={handleChatBtnClick}
      >
        <FontAwesomeIcon icon={faCommentDots} /> Live Chat
      </button>

      {/* Mobile */}
      <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
        <div onClick={openNav} className="mobile-navbar-close">
          <FontAwesomeIcon icon={faXmark} className="hamb-icon" />
        </div>

        <ul className="mobile-navbar-links">
          <li>
            <Link onClick={openNav} to="/">
              Inicio
            </Link>
          </li>
          <li>
            <a onClick={openNav} href="#services">
              Servicios
            </a>
          </li>
          <li>
            <a onClick={openNav} href="#about">
              Sobre nosotros
            </a>
          </li>
          <li>
            <a onClick={openNav} href="#reviews">
              Experiencias
            </a>
          </li>
          <li>
            <a onClick={openNav} href="#doctors">
              Profesionales
            </a>
          </li>
          <li>
            <a onClick={openNav} href="#contact">
              Contacto
            </a>
          </li>
        </ul>
      </div>

      {/* Hamburger Icon */}
      <div className="mobile-nav">
        <FontAwesomeIcon
          icon={faBars}
          onClick={openNav}
          className="hamb-icon"
        />
      </div>
    </div>
  );
}

export default Navbar;
