import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../Styles/LegalDocs.css";

function LegalDocs() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  return (
    <div className="legal-section-title">
      <h1 className="legal-siteTitle">
        <Link to="/">
          EServices <span className="legal-siteSign">Entertained</span>
        </Link>
      </h1>

      <div className="legal-text-content">
        <p className="legal-title">Información general</p>
        <p className="legal-description">
          Bienvenido a EServices Entertained, su socio confiable en soluciones tecnológicas. 
          Nos especializamos en ofrecer servicios personalizados de desarrollo de software, 
          análisis de datos y gestión de proyectos. Al utilizar nuestra plataforma, usted acepta 
          los términos descritos en nuestra Política de Privacidad y Condiciones de Servicio.
        </p>

        <p className="legal-title">Política de privacidad</p>
        <p className="legal-description">
          Su privacidad es nuestra prioridad. Nuestra Política de Privacidad describe cómo recopilamos, 
          utilizamos y protegemos su información personal. Garantizamos un manejo seguro y confidencial 
          de sus datos, asegurando la máxima transparencia y seguridad.
        </p>

        <p className="legal-title">Términos de servicio</p>
        <p className="legal-description">
          Al utilizar los servicios de EServices Entertained, acepta nuestras Condiciones de Servicio. 
          Esto incluye las directrices para usar nuestra plataforma, interactuar con nuestro equipo y las 
          responsabilidades de ambas partes. Le recomendamos leer estos términos detenidamente.
        </p>

        <p className="legal-title">Consultas</p>
        <p className="legal-description">
          Nuestro equipo de expertos está disponible para ofrecerle consultas personalizadas 
          sobre sus proyectos tecnológicos. Provea información clara y específica para que podamos 
          brindarle la mejor solución posible.
        </p>

        <p className="legal-title">Cómo funciona</p>
        <p className="legal-description">
          En EServices Entertained, simplificamos el acceso a soluciones tecnológicas de calidad. 
          Puede contactarnos para discutir sus necesidades, recibir una propuesta adaptada y trabajar 
          con un equipo dedicado para alcanzar sus objetivos. Nos enfocamos en resultados eficientes y escalables.
        </p>
      </div>

      <div className="legal-footer">
        <p>© 2000-2025 EServices Entertained</p>
      </div>
    </div>
  );
}

export default LegalDocs;
